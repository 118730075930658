.news {
  width: 100%;
  background: black;
  color: white;
  padding: 100px 40px;
}

.newsHeading h2 {
  color: red;
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;
}

.slider-container {
  margin: auto;
  padding: 40px 0;
}

.news-card-wrapper {
  padding: 0 5px;
}

.news-card {
  background-color: #f5f7fa;
  height: 500px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
}

.news-images {
  display: flex;
  justify-content: space-between;
}

.news-image {
  box-sizing: border-box;
  height: 160px;
  border-radius: 4px;
}

.news-image.single {
  width: 100%; /* Full width for a single image */
}

.news-image.double {
  width: 49%; /* 50% width for two images */
}
.cover {
  object-fit: cover;
}
.scale {
  object-fit: scale-down;
}

.news-title {
  padding: 0 20px;
  margin-top: 10px;
  color: black;
  text-align: center;
}

.news-description {
  padding: 0 20px 20px 20px;
  margin-top: 10px;
  font-size: 1rem;
  color: black;
  text-align: left;
}

.news-date {
  padding: 0 20px 20px 20px;
  margin-top: 10px;
  font-size: 0.875rem;
  color: rgb(53, 51, 51);
  text-align: left;
}

.news .slick-dots li button::before {
  color: #cccccc;
}

.news .slick-dots li.slick-active button:before {
  color: #000000; /* Black for active dot */
}

@media screen and (max-width: 1024px) {
  .newsHeading h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 625px) and (min-width: 500px) {
  .slider-container {
    width: 80%;
  }
}
