.dealers {
  padding: 50px;
  text-align: center;
  background-color: black;
}

.dealersHeading h2 {
  margin-bottom: 20px;
  font-size: 35px;
  color: red;
}

.dealers .slider-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .dealersHeading h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 700px) {
  .dealers .slider-container {
    width: 100%;
  }
}

.dealers .slick-slide {
  opacity: 0.5;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.dealers .slick-center {
  transform: scale(1.2, 1.1);
  opacity: 1;
  position: relative;
  z-index: 2;
}

.dealers .slick-slide img {
  width: 90%;
  margin: 0 auto;
}

.dealers .slick-center img {
  width: 100%;
}
