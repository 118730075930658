.admin-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.admin-title {
  text-align: center;
  color: black;
  font-size: 36px;
  margin-bottom: 30px;
}

.news-section {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.add-news-form,
.news-list {
  flex: 1;
}

.add-news-form {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.add-news-form h2 {
  color: #e60000;
  margin-bottom: 20px;
}

.input-field,
.textarea-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.textarea-field {
  height: 120px;
  resize: none;
}

.add-news-btn {
  width: 100%;
  background-color: #e60000;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-news-btn:hover {
  background-color: #b80000;
}

.news-list h2 {
  color: #e60000;
  margin-bottom: 20px;
}

.news-item {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}

.news-item h3 {
  color: black;
  font-size: 20px;
}

.news-item p {
  color: #333;
  font-size: 16px;
}

.image-url {
  width: 150px;
  object-fit: cover;
  border-radius: 4px;
}
