.wrapper {
  background-color: black;
}

.companies-title {
  font-weight: bold;
  color: red;
  font-size: 35px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;
  text-align: center;
  margin: 20px 0;
}

.carousel-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.carousel-item {
  padding: 10px;
  text-align: center;
}

.carousel-logo {
  display: inline-block;
  margin: 0 auto;
  width: 150px;
  height: 100px;
  object-fit: scale-down;
  background-color: #fff;
  padding: 5px;
  border-radius: 16px;
}

.carousel-title {
  font-size: 1rem;
  color: white;
  word-wrap: break-word;
  margin-top: 5px;
}

@media screen and (max-width: 1024px) {
  .companies-title {
    font-size: 25px;
  }
}
